<template>
  <div class="vx-col md:w-1/1 w-full">
    <vx-card title="Credit Limit Reguler">
      <!-- Tabs -->
      <vs-tabs v-model="selected.tab">
        <vs-tab label="Draft">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="0"
              :generated="11"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
        <vs-tab label="Generated">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="1"
              :generated="1"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
        <vs-tab label="Done">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="2"
              :done="true"
              :generated="11"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>

        <vs-tab label="Cancel">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="3"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="con-tab-ejemplo">
            <list-export
              :selected="selected"
              :option="option"
              @changeTab="selectedTab"
            ></list-export>
          </div>
        </vs-tab>
        <vs-tab label="Import">
          <div class="con-tab-ejemplo">
            <list-import
              :selected="selected"
              :option="option"
              :generated="11"
              @changeTab="selectedTab"
            ></list-import>
          </div>
        </vs-tab>
        <vs-tab label="Generate Log">
          <div class="con-tab-ejemplo">
            <list-credit-risk
              :selected="selected"
              :option="option"
              :status="1"
              :isLog="true"
              :done="true"
              :generated="11"
              @changeTab="selectedTab"
            ></list-credit-risk>
          </div>
        </vs-tab>
      </vs-tabs>
    </vx-card>
  </div>
</template>

<script>
import list from "./list/list.vue";
import form from "./form/form.vue";
import listExport from "./export/index.vue";
import listImport from "./import/index.vue";

export default {
  components: {
    "list-credit-risk": list,
    "form-credit-risk": form,
    "list-export": listExport,
    "list-import": listImport,
  },
  data() {
    return {
      option: {},
      selected: {
        tab: 0,
      },
    };
  },
  methods: {
    selectedTab(value = false) {
      this.selected.tab = value;
    },
  },
};
</script>
