<template>
  <vx-card>
    <!-- <div class="vx-col mb-6 mt-3">
      <div class="vx-row w-1/2">
        <div class="vx-col sm:w-1/3 w-full">
          <span>CL Request ID</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input v-model="data.code" type="text" disabled />
        </div>
      </div>
      <div class="vx-row w-1/2 mt-2">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input v-model="data.territory_name" type="text" disabled />
        </div>
      </div>
      <div class="vx-row w-1/2 mt-2">
        <div class="vx-col sm:w-1/3 w-full">
          <span>Customer Group1</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <vs-input v-model="data.customer_group_name" type="text" disabled />
        </div>
      </div>
    </div> -->

    <vs-table
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
      style="overflow-y: auto"
    >
      <template slot="header"> </template>
      <template slot="thead">
        <vs-th sort-key="customer_code">Customer Codes</vs-th>
        <vs-th sort-key="customer_name">Customer Name</vs-th>
        <vs-th sort-key="status">Status</vs-th>
        <vs-th sort-key="message">Message</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>{{ tr.customer_code }}</vs-td>
          <vs-td>{{ tr.customer_name }}</vs-td>
          <vs-td>{{ tr.status }}</vs-td>
          <vs-td>{{ tr.message }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row mb-12">
      <div class="vx-col w-full flex items-center space-x-2">
        <vs-button
          class="mt-2"
          color="danger"
          type="border"
          icon-pack="feather"
          icon="icon-arrow-left"
          @click="closeDetail()"
          >Back</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>
<script>
import moment from "moment";

export default {
  components: {},
  props: {
    id: {
      type: Number,
    },
    show: {
      type: Boolean,
      default: false,
    },
    done: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      deleteId: null,
      clRequestId: "",
      territory: "",
      customerGroup1: "",
      dateStart: moment().format("YYYY-MM-DD"),
      dateEnd: moment().format("YYYY-MM-DD"),
      table: this.tableDefaultState(),
    };
  },
  mounted() {
    this.getData();
    //set default all data[indextr].credit_limit_request is current_credit_limit
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    closeDetail(params = false) {
      this.$emit("closeDetail", params);
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/credit-limit-regulers-log", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            sort: this.table.sort,
            credit_limit_reguler_id: this.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
  },
  watch: {
    draw() {
      this.getData();
    },
  },
  filters: {},
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
